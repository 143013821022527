import React from "react";

import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu",
          headerFluid: true,
          footerStyle: "style5",
        }}
      >
        <div className="d-flex align-items-center pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-12">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8 col-12-sm order-1 pe-lg-20">
                <div className="pt-12 pb-10 mx-auto">
                  <iframe
                    id="JotFormIFrame-63247364713962"
                    title="streaka ~ contact"
                    allowFullScreen={true}
                    allow="geolocation; microphone; camera"
                    src="https://form.jotform.com/63247364713962"
                    frameBorder="0"
                    style={{
                      minWidth: "100%",
                      height: "596px",
                      border: "none",
                    }}
                    scrolling="no"
                  >
                    {" "}
                  </iframe>
                </div>
              </div>
              <div className="dark-mode-texts col-lg-4 col-10-sm mx-8 mx-lg-0 order-2 gradient-background px-12 pt-12 pb-12 align-center rounded-10 shadow-3">
                <h4 style={{ fontWeight: "normal" }}>With Streaka you can:</h4>
                <ul className="bullet-points">
                  <li>Manage your videos easily</li>
                  <li>Share them to your customers and friends</li>
                  <li>Brand your videos and pages</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="pb-13 pb-md-18 pb-lg-18">
            {/* Faqs */}
            <div className="row justify-content-center">
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="me-6">
                    <div className="bg-light-green circle-28 text-white mt-1">
                      <i className="fa fa-comments" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">General Communication</h4>
                    <p className="font-size-5 pe-md-10 pe-lg-0 mb-0">
                      For general queries, including partnership opportunities,
                      please email{" "}
                      <a href="mailto:help@streaka.com">help@streaka.com</a>.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="me-6">
                    <div className="bg-light-green circle-28 text-white mt-1">
                      <i className="fa fa-hands-helping" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      Technical or account support
                    </h4>
                    <p className="font-size-5 pe-md-10 pe-lg-0 mb-0">
                      We’re here to help! If you have technical issues,{" "}
                      <a
                        href="https://help.streaka.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        contact support.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
